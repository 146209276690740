import React, { FunctionComponent, useState } from 'react';
import Heading from "../components/Heading";
import Section from "../components/Section";
import EnvelopeIcon from "../assets/icons/ico_envelope_blue.svg";
import MapIcon from "../assets/icons/ico_map-pin_blue.svg";
import ContactForm from "../forms/ContactForm";
import Button from '../components/Button/Button';
import useTranslations from '../components/useTranslations';

const Contact: FunctionComponent = () => {
    const { contactForm, pages } = useTranslations()
    const [success, setSuccess] = useState<boolean>(false)

    return (
        <Section className={'py-20'}>
            <div className={'flex flex-wrap'}>
                <div className={'w-full sm:w-1/2 pr-0 sm:pr-4'}>
                    <Heading type='h1' className={'mb-4'}>{pages.contact.title}</Heading>
                    <div className={'text-ink-60 whitespace-pre-wrap mb-6'}>
                        LEGALIA, advokátska kancelária, s. r. o.<br />
                        <br />
                        Tower 115<br />
                        13. poschodie<br />
                        Pribinova 4195/25<br />
                        811 09 Bratislava<br />
                        <br />
                        {pages.contact.business_id}: 46 488 995<br />
                        {pages.contact.tax_id}: 2820015759<br />
                        {pages.contact.vat}: SK 2820015759<br />
                    </div>
                    <div className={'space-y-4 mb-4 flex map-contact-links'}>
                        <p className={'flex text-blue-100 space-x-3'}>
                            <img src={EnvelopeIcon} className={'w-6 h-6 text-blue-100'} alt="" />
                            <a href="mailto:legalia@legalia.sk" className={'underline'}>legalia@legalia.sk</a>
                        </p>
                    </div>
                    <div>
                        <iframe
                            width="100%"
                            height="350"
                            frameBorder="0"
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2662.4021282322383!2d17.125648076938504!3d48.141052150918505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c893e84030855%3A0xd1240d3861513e7!2sPribinova%204195%2F25%2C%20811%2009%20Bratislava!5e0!3m2!1sen!2ssk!4v1700750199841!5m2!1sen!2ssk"
                            allowFullScreen>
                        </iframe>
                    </div>
                </div>
                <div className={'w-full sm:w-1/2 pl-0 sm:pl-4 mt-4 sm:mt-0'}>
                    <div className={(success ? "bg-success h-full flex " : "bg-ink-5 ") + "p-12 rounded-md"}>

                        {
                            success ?
                                (
                                    <>
                                        <div className={'text-center m-auto'}>
                                            <Heading type='h4' className={'mb-8 font-bold'}>
                                                {contactForm.success.text1}
                                            </Heading>
                                            <Heading type='h4' className={'mb-8 font-bold'}>
                                                {contactForm.success.text2}
                                            </Heading>
                                            <Button onClick={() => setSuccess(false)} chevron={false} variant={'border'}>{contactForm.success.start}</Button>
                                        </div>
                                    </>
                                ) :
                                (
                                    <>
                                        <Heading type='h4' className={'mb-8 font-medium'}>{contactForm.heading}</Heading>
                                        <ContactForm setSuccess={setSuccess} />
                                    </>
                                )
                        }

                    </div>
                </div>
            </div>
        </Section>
    );
};

export default Contact;
