import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import FormField from "../components/FormField";
import Button from "../components/Button/Button";
import useTranslations from '../components/useTranslations';

type ContactFormProps = {
    setSuccess: Dispatch<SetStateAction<boolean>>
};

type ContactFormValues = {
    email: string,
    fullName: string,
    phone: string,
    message: string,
};

const ContactForm: FunctionComponent<ContactFormProps> = ({ setSuccess, ...props }) => {
    const { form, actions } = useTranslations();

    const initialValues: ContactFormValues = {
        email: '',
        fullName: '',
        phone: '',
        message: '',
    };

    const ContactFormValidation = Yup.object().shape({
        email: Yup.string().email(form.error.email.invalid).required(form.required),
        fullName: Yup.string()
            .min(2, form.error.fullName.short)
            .max(50, form.error.fullName.long)
            .required(form.required),
        phone: Yup.string().matches(/^(\+[1234]{3})([\d]{9})$/, { message: form.error.phone.matches }).required(form.required),
        message: Yup.string().required(form.required)
    });

    const handleSubmit = async (values: ContactFormValues) => {
        setSuccess(true)

        const response = await (await fetch(`/.netlify/functions/sendEmail`, {
            method: 'POST',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({ title: "LEGALIA - CONTACT FORM", ...values })
        })).json();

        setSuccess(true)
    }

    return (
        <div>
            <Formik
                initialValues={initialValues}
                validationSchema={ContactFormValidation}
                onSubmit={handleSubmit}
            >
                {({ errors, touched }) => (
                    <Form>
                        <div className={'space-y-6 mb-6'}>
                            <FormField name="email" type="text" label={form.label.email} markRequired placeholder={form.placeholder.email} />
                            <FormField name="fullName" type="text" label={form.label.fullName} markRequired placeholder={form.placeholder.fullName} />
                            <FormField
                                name="phone"
                                type="text"
                                label={form.label.phone}
                                placeholder={form.placeholder.phone}
                                hint={form.hint.phone}
                                markRequired
                            />
                            <FormField name="message" type="textarea" label={form.label.message} markRequired placeholder={form.placeholder.message} />
                        </div>
                        <Button chevronVariant={'white'} type="submit" variant={'secondary'}>{actions.send}</Button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ContactForm;
